import React, { useState } from "react"
import { WPApi } from "@api"
import { useStaticQuery, graphql } from "gatsby"
import { Layout, Parallax } from "@components/layout"

import {
  page__wrapper,
  page__content,
  customer__form,
} from "./styles/answers.module.scss"

const AnswerForm = (questions) => {
  const [state, setState] = useState({
    first_answer: "",
    second_answer: "",
    msg: "",
  })

  const data = questions.questions
  return (
    <form
      className={customer__form}
      onSubmit={(ev) => {
        ev.preventDefault()
      }}
    >
      <h5>{data.firstquestion}</h5>
      <div className="row">
        <input
          onClick={() => setState({ ...state, first_answer: data.answer1 })}
          type="radio"
          id="answer1"
          name="answer"
          value={data.answer1}
        />
        <label for="answer1">{data.answer1}</label>
      </div>
      <div className="row">
        <input
          onClick={() => setState({ ...state, first_answer: data.answer2 })}
          type="radio"
          id="answer2"
          name="answer"
          value={data.answer2}
        />
        <label for="answer2">{data.answer2}</label>
      </div>
      <h5>{data.secondquestion}</h5>
      <div className="row">
        <textarea
          value={state.second_answer}
          onChange={(ev) =>
            setState({ ...state, second_answer: ev.target.value })
          }
        />
      </div>
      <div className="row justify-content-center">
        <button
          onClick={() =>
            WPApi.sendFeedback(
              data.firstquestion,
              state.first_answer,
              data.secondquestion,
              state.second_answer
            )
              .then((data) => {
                setState({
                  ...state,
                  first_answer: "",
                  second_answer: "",
                  msg: data.message,
                })
              })
              .catch((data) => {
                console.log("err", data)
              })
          }
        >
          Wyślij
        </button>
      </div>
      {state.msg !== "" ? (
        <div className="row justify-content-center">{state.msg}</div>
      ) : null}
    </form>
  )
}

const Answers = ({ pageContext }) => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "answers") {
          ACF_question1 {
            questions {
              firstquestion
              answer1
              answer2
              secondquestion
            }
          }
        }
      }
    }
  `)

  const { questions } = WP.pageBy.ACF_question1

  return (
    <Layout
      animation={false}
      seo={pageContext.page.seo}
      siteMetaData={pageContext.page.siteMetaData}
    >
      <div>
        <Parallax height={300} title={'Podziel się swoją opinią'}>
        </Parallax>
        <div className={page__wrapper}>
          <div className={page__content}>
            <AnswerForm questions={questions} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Answers
